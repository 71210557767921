html {
  overflow-x: hidden;
}

.layout {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    padding-top: var(--space-y);
    padding-bottom: var(--space-y-);
}

.title {
    color: var(--color-lightest);
    font-weight: 500;
    font-size: 4.4rem;
    line-height: 1.1;
    text-transform: lowercase;
    margin-bottom: 2.8vw;
}

.discount{
   font-weight: 500;
}

sup {
    font-weight: normal;
    vertical-align: super;
    font-size: small;
}

.description {
    color:  var(--color-lightest);
}

.c-btn {
    margin-left: 20px;
    margin-right: 20px;
    /* padding: 8px 23px; */
}

.btn-color {
    border-color: var(--color-primary) !important;
    border: solid;
    background-color: #246082;
}

.btn-light {
    border-color: var(--color-light);
    border: solid;
    background-color: transparent;
    color: var(--color-light);
}

.logo {
  width: 40px;
  margin: 0 auto;
}


.footer-logo{
    display: inline-block;
    /* position: absolute; */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.footer {
  width: 100%;
  text-align: center;
}
.footer-logo:before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
}

.footer-logo--freezone:before{
    padding-bottom: 100px;
}

.footer-logo--freezone {
    background-image: url(../public/assets/images/wtc-logo-z-white.svg);
    width: 50px;
    bottom: 0;
    margin: 0 auto;
}

.c-heading__align{
  float: left;
  display: flex;
}

.c-form__input__small {
  display: block;
  padding-bottom: 10px;
  margin-top: 12px;
  border-bottom: 1px solid var(--color-lightest);
  font-size: 1.7rem;
  color: #d7efff;

  
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-light);
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-light);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-light);
  }
  

/* Layout */

.c-layout{
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--color-primary);
  z-index: 5;
  text-align: center;
  text-align: -webkit-center;
}

.c-layout-deco{
  position: absolute;
  display: block;
  width: 25vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(../public/assets/images/wtc-o-popup-logo.svg);
}

.c-layout-deco.top{
  right: -70px;
  top: -70px;
}

.c-layout-deco.bottom{
  left: -70px;
  bottom: -70px;
}


.c-layout-deco:before{
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 102.32%;
}

.c-layout.not-fixed{
  position: relative;
  height: auto;
  min-height: 100vh;
}

.c-layout__holder{
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.c-layout__title{
  color: var(--color-lightest);
  font-weight: 500;
  font-size: 5.4rem;
  line-height: 1.1;
  text-transform: lowercase;
  margin-bottom: 6.8vw;
}

.c-layout__text{
  color: var(--color-lightest);
  max-width: 240px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.5rem;
  line-height: 1.5;
}

.c-layout__close{
  position: absolute;
  right: 19px;
  top: 26px;
  color: var(--color-lightest);
  font-size: 1.8rem;
  z-index: 99;
}

.c-layout.is-visible{
  animation: showPopup 500ms ease-out;
  animation-fill-mode: forwards;
}
.c-layout.is-visible .c-layout__holder{
  animation: showPopupHolder 500ms cubic-bezier(0.22, 0.61, 0.36, 1) 100ms;
  animation-fill-mode: forwards;
}

.c-layout.is-closing{
  opacity: 1;
  animation: hidePopup 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
  animation-fill-mode: forwards;
}
.c-layout.is-closing .c-layout__holder{
  animation: hidePopupHolder 600ms cubic-bezier(0.22, 0.61, 0.36, 1);
  animation-fill-mode: forwards;
}

/* error */

.c-layout--error{
  background-color: var(--color-red-lighter);
}

/* .c-layout--error .c-layout-deco{
  background-image: url(../images/wtc-o-popup-logo-gray.svg);
} */

.c-layout--error .c-layout__title{
  font-size: 3.3rem;
  color: var(--color-red);
}

.c-layout--error .c-layout__text{
  color: var(--color-gray);
}

.c-layout--error  .c-layout__close{
  color: var(--color-red);
}


/* ********************** Iphone 5/SE ********************** */

@media (max-width: 374px) {

  .c-layout__title{
      font-size: 4.1rem;
      margin-top: -25px;
      margin-bottom: 4.4vw;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
  }
  
  .c-layout--error .c-layout__title{
      font-size: 2.5rem;
  }

}